<template>
  <g :fill="color" fill-rule="nonzero" transform="translate(-8, -8) scale(0.05, 0.05)">
    <path
      :fill="color"
      d="M410.66,558.55c-90.78,0-164.63-73.85-164.63-164.63,0-66.5,39.62-126.1,100.93-151.85l22.1-9.28-12.1,20.69c-13.23,22.63-20.22,48.53-20.22,74.9,0,81.95,66.67,148.63,148.63,148.63,19.94,0,39.29-3.88,57.5-11.53l22.11-9.29-12.1,20.7c-14.26,24.39-34.68,44.84-59.06,59.14-25.12,14.73-53.88,22.52-83.16,22.52Z"
    />
    <path
      :fill="color"
      d="M497.97,421.92l-49.84-26.2-49.84,26.2,9.52-55.5-40.32-39.31,55.73-8.1,24.92-50.5,24.92,50.5,55.73,8.1-40.32,39.31,9.52,55.5Z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
